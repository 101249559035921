@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,500;1,600&display=swap');

*, *::after , *::before{
  margin: 0;
  padding: 0;
}
.no-scroll {
  overflow: hidden;
}



.container {
  position: relative;
  width: 60px; /* Set the width as per your design */
  height: 50px; /* Set the height as per your design */
  overflow: hidden; /* Hide overflow content */
  background-color: white;
  border-radius: 40px;
  transition: all 0.5s;
}

.logo {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.5s ease; /* Transition the transform property */
}

.text {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  opacity: 0; /* Initially hide the text */
}
.container:hover {
  width: 230px;
}
.container:hover .logo {
  left: 75%; /* Slide the logo to the right */
}

.container:hover .text {
  opacity: 1; /* Make the text visible */
  transition: opacity 1s 0.3s ease ; /* Transition the opacity property */

}
.container:not(:hover) .text {
  opacity: 0; /* Hide the text when not hovered */
}

@media screen and (max-width: 768px) {
  .container {
    width: 230px;
  }

  .container .logo {
    left: 75%; /* Adjust logo position for mobile devices */
  }
  .container .text {
    opacity: 1 !important; /* Make the text visible */
  
  }
}
/* 
@keyframes logo {
  from{
    transform:translateY(-50%) rotate(0deg)  ;
  }
  to{
    transform:translateY(-50%) rotate(360deg)  ;
  }
}
@keyframes logoback {
  from{
    transform:translateY(-50%) rotate(360deg)  ;
  }
  to{
    transform:translateY(-50%) rotate(0deg)  ;
  }
} */




::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f7eded;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(222, 6, 6);
  border-radius: 5px;
cursor: pointer;
}
::-moz-scrollbar-thumb {
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e22b2b;

    cursor: pointer;
  
}














.editapp-mobile::-webkit-scrollbar {
  width: 5px;
}
.editapp-mobile::-webkit-scrollbar-thumb {
  background: #e38585;
cursor: pointer;
}
.editapp-mobile::-moz-scrollbar-thumb {
  cursor: pointer;
}
.cart::-webkit-scrollbar {
  width: 5px;
}
.cart::-webkit-scrollbar-thumb {
  background: #e38585;
cursor: pointer;
}
.cart::-moz-scrollbar-thumb {
  cursor: pointer;
}



@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}